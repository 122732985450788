/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

require('typeface-roboto')

const React = require('react')

const theme = require('@src/style/theme').default
const { ThemeProvider } = require('styled-components')
const { GlobalStyle } = require('@src/style/globalStyle')

export const wrapRootElement = ({ element }) => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      {element}
    </ThemeProvider>
  )
}
