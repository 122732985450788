import styled from 'styled-components'
import typography from '@src/style/typography'
import device from './device'

export const H1 = styled.h1`
  ${typography.headline.M}
`
export const H2 = styled.h2`
  ${typography.headline.S}
`
export const H3 = styled.h3`
  ${typography.headline.XS}
`
export const P = styled.p``
export const PXXS = styled(P)`
  ${typography.paragraph.XXS}
`
export const PXS = styled(P)`
  ${typography.paragraph.XS}
`
export const PS = styled(P)`
  ${typography.paragraph.S}
`
export const PM = styled(P)`
  ${typography.paragraph.S}
`
export const PL = styled(P)`
  ${typography.paragraph.L}
`
export const PXL = styled(P)`
  ${typography.paragraph.XL}
`
