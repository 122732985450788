const size = {
  mobileS: '320',
  mobileM: '375',
  mobileL: '425',
  tablet: '768',
  laptop: '1024',
  laptopL: '1440',
  desktop4K: '2560'
}

export default {
  mobileS: `(min-width: ${size.mobileS}px)`,
  mobileM: `(min-width: ${size.mobileM}px)`,
  mobileL: `(min-width: ${size.mobileL}px)`,
  tablet: `(min-width: ${size.tablet}px)`,
  laptop: `(min-width: ${size.laptop}px)`,
  laptopL: `(min-width: ${size.laptopL}px)`,
  desktop: `(min-width: ${size.desktop4K}px)`,

  only: {
    mobileS: `(min-width: ${size.mobileS}px) and (max-width:${size.mobileM - 1}px)`,
    mobileM: `(min-width: ${size.mobileM}px) and (max-width:${size.mobileL - 1}px)`,
    mobileL: `(min-width: ${size.mobileL}px) and (max-width:${size.tablet - 1}px)`,
    tablet: `(min-width: ${size.tablet}px) and (max-width:${size.laptop - 1}px)`,
    laptop: `(min-width: ${size.laptop}px) and (max-width:${size.laptopL}px)`,
    laptopL: `(min-width: ${size.laptopL}px) and (max-width:${size.desktop4K -
      1}px)`,
    desktop: `(min-width: ${size.desktop4K}px)`,
    mobile: `(min-width: ${size.mobileS}px) and (max-width:${size.tablet - 1}px)`,
    laptops: `(min-width: ${size.laptop}px) and (max-width:${size.desktop4K -
      1}px)`
  }
}
