import { css } from 'styled-components'
import { device } from '@src/style'

const FontSettings = css`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
`

const typography = {
  paragraph: {
    XXS: css`
      ${FontSettings}
      font-size: 0.75rem;
      line-height: 1.4rem;
    `,
    XS: css`
      ${FontSettings}
      font-size: 1rem;
      line-height: 1.5rem;
    `,
    S: css`
      ${FontSettings}
      font-size: 1.125rem;
      line-height: 2rem;
    `,
    M: css`
      ${FontSettings}
      font-size: 1.3rem;
      line-height: 2.25rem;
    `,
    L: css`
      ${FontSettings}
      font-size: 1.5rem;
      line-height: 2.25rem;
    `,
    XL: css`
      ${FontSettings}
      font-size: 2.25rem;
      line-height: 3rem;
    `
  },
  headline: {
    XS: css`
      ${FontSettings}
      font-size: 1.5rem; 
      line-height: 2rem;
    `,
    S: css`
      ${FontSettings}
      font-size: 2.2rem; 
      line-height: 2rem;
    `,
    M: css`
      ${FontSettings}
      font-size: 3rem; //48px
      line-height: 3.5rem;
    `,
    L: css`
      ${FontSettings}
      font-size: 6rem; //96px
      line-height: 7rem; //112px
    `,
    L_LAPTOP: css`
      ${FontSettings}
      font-size: 5rem;
      line-height: 6rem;
    `
  }
}

export default typography