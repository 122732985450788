import { device } from '@src/style'
import typography from './typography'

const black = {
  background: '#101010',
  text: '#95a5a6',
  menuBackground: '#000',
  menuText: '#888',
  menuTextHover: '#fff',
  borders: '#888'
}

const colors = black

export default {
  typography,
  colors: colors,
  textColor: colors.text,
  body: {
    background: colors.background,
  },
  button: {
    normal: {
      text: '#fff',
      background: colors.borders,
    },
    hover: {
      background: colors.text,
    }
  },
  widthLimiter: `
    @media ${device.mobileS} {
      min-width: 280px;
      max-width: 280px;
    }
    @media ${device.mobileM} {
      min-width: 360px;
      max-width: 360px;
    }
    @media ${device.mobileL} {
      min-width: 360px;
      max-width: 360px;
    }
    @media ${device.tablet} {
      min-width: 680px;
      max-width: 680px;
    }
    @media ${device.laptop} {
      min-width: 900px;
      max-width: 900px;
    }
    @media ${device.laptopL} {
      min-width: 1300px;
      max-width: 1300px;
    }
    @media ${device.desktop} {
      min-width: 2000px;
      max-width: 2000px;
    }
  `,
  blogWidthLimiter: `
    min-width: 100%;
    max-width: 100%;

    @media ${device.tablet} {
      min-width: 680px;
      max-width: 680px;
    }
    @media ${device.laptop} {
      min-width: 800px;
      max-width: 800px;
    }
  `,
}
